// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-dna-js": () => import("./../../../src/pages/dna.js" /* webpackChunkName: "component---src-pages-dna-js" */),
  "component---src-pages-elisa-js": () => import("./../../../src/pages/elisa.js" /* webpackChunkName: "component---src-pages-elisa-js" */),
  "component---src-pages-ilman-nettia-js": () => import("./../../../src/pages/ilman-nettia.js" /* webpackChunkName: "component---src-pages-ilman-nettia-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lapselle-js": () => import("./../../../src/pages/lapselle.js" /* webpackChunkName: "component---src-pages-lapselle-js" */),
  "component---src-pages-liittymatarjous-js": () => import("./../../../src/pages/liittymatarjous.js" /* webpackChunkName: "component---src-pages-liittymatarjous-js" */),
  "component---src-pages-mobiililaajakaista-js": () => import("./../../../src/pages/mobiililaajakaista.js" /* webpackChunkName: "component---src-pages-mobiililaajakaista-js" */),
  "component---src-pages-moi-js": () => import("./../../../src/pages/moi.js" /* webpackChunkName: "component---src-pages-moi-js" */),
  "component---src-pages-mokkula-js": () => import("./../../../src/pages/mokkula.js" /* webpackChunkName: "component---src-pages-mokkula-js" */),
  "component---src-pages-nettiliittyma-js": () => import("./../../../src/pages/nettiliittyma.js" /* webpackChunkName: "component---src-pages-nettiliittyma-js" */),
  "component---src-pages-operaattorit-js": () => import("./../../../src/pages/operaattorit.js" /* webpackChunkName: "component---src-pages-operaattorit-js" */),
  "component---src-pages-prepaid-js": () => import("./../../../src/pages/prepaid.js" /* webpackChunkName: "component---src-pages-prepaid-js" */),
  "component---src-pages-puhelinliittymavertailu-js": () => import("./../../../src/pages/puhelinliittymavertailu.js" /* webpackChunkName: "component---src-pages-puhelinliittymavertailu-js" */),
  "component---src-pages-suoramarkkinointikielto-js": () => import("./../../../src/pages/suoramarkkinointikielto.js" /* webpackChunkName: "component---src-pages-suoramarkkinointikielto-js" */),
  "component---src-pages-telia-js": () => import("./../../../src/pages/telia.js" /* webpackChunkName: "component---src-pages-telia-js" */),
  "component---src-templates-blog-template-js": () => import("./../../../src/templates/blogTemplate.js" /* webpackChunkName: "component---src-templates-blog-template-js" */),
  "component---src-templates-blogs-template-js": () => import("./../../../src/templates/blogsTemplate.js" /* webpackChunkName: "component---src-templates-blogs-template-js" */),
  "component---src-templates-info-template-js": () => import("./../../../src/templates/infoTemplate.js" /* webpackChunkName: "component---src-templates-info-template-js" */),
  "component---src-templates-speed-test-template-js": () => import("./../../../src/templates/speedTestTemplate.js" /* webpackChunkName: "component---src-templates-speed-test-template-js" */)
}

